import React from 'react'
import { Message } from '@/components/Message'

const IS_BROWSER = typeof window !== 'undefined'

// https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-guide.md#enable-previews-in-prismic

const PreviewUnpublished = () => {
  const previewData = IS_BROWSER ? window.__PRISMIC_PREVIEW_DATA__ : undefined
  // perform any logic from previewData to determine the correct page or template component to use.
  console.log(previewData) // eslint-disable-line no-console

  return <Message>Error: This page has not been published yet.</Message>
}

export default PreviewUnpublished
